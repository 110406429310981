define('frontend-app/pods/members/view/controller', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'frontend-app/config/environment', 'frontend-app/metadata'], function (exports, _fileSaver, _environment, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_fileSaver.default, {

		url: Ember.computed('model.member', function () {
			return _environment.default.base_url + '/members/' + this.get('model.member.slug');
		}),

		showJobDescription: false,

		jobSortDefinition: ['doc.deadline'],

		jobsSorted: Ember.computed.sort('model.jobs', 'jobSortDefinition'),

		fileList: Ember.computed('model.files', function () {
			var self = this;
			if (!this.get('model.files')) return [];
			return this.get('model.files').map(function (object) {
				return Object.keys(object.doc._attachments || []).map(function (item) {
					return {
						filename: item,
						length: object.doc._attachments[item]['length'],
						type: object.doc._attachments[item]['content_type'],
						fileid: object.id,
						description: object.doc.description,
						title: object.doc.title
					};
				});
			});
		}),

		file_icon_lookup: _metadata.default.fileIcons,

		actions: {
			downloadFile: function downloadFile() {
				var myString = "BEGIN:VCARD\nN:" + this.get('model.member.contact_surname') + ";" + this.get('model.member.contact_name') + ";;;\nTITLE:" + this.get('model.member.contact_title') + "\nORG:" + this.get('model.member.name') + "\nTEL;Phone:" + this.get('model.member.phone') + "\nEMAIL;WORK:" + this.get('model.member.contact_email');
				this.get('model.member.address').forEach(function (address) {
					var address2 = "";
					if (address.address.address2.length) {
						address2 = "," + address.address.address2;
					}
					myString += "\nADR;" + address.address.label + ";PARCEL;:;;" + address.address.address1 + address2 + ";" + address.address.city + ";" + address.address.state + ";" + address.address.code + ";" + address.address.country;
				});
				myString += "\nEND:VCARD";
				this.saveFileAs(this.get('model.member.name') + ".vcf", new Blob([myString]), "text/vcard");
			},

			toggleJobDescription: function toggleJobDescription() {
				this.toggleProperty('showJobDescription');
			}
		}
	});
});