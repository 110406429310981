define('frontend-app/pods/careers/index/controller', ['exports', 'npm:elasticlunr'], function (exports, _npmElasticlunr) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({
		sortedCareers: Ember.computed.sort('model.careers', 'sortDefinition'),
		sortBy: 'name',
		sortDefinition: Ember.computed('sortBy', function () {
			return [this.get('sortBy')];
		}),
		index: (0, _npmElasticlunr.default)(function () {
			this.addField('name');
			this.addField('definition');
			this.addField('duties');
			this.addField('description');
			this.addField('requirements');
			this.addField('location');
			this.setRef('id');
		}),
		searchState: Ember.Object.create({
			mode: 'browse',
			filter_keyword: ''
		}),
		inputKeyword: '',
		onFilterTextChange: Ember.observer('inputKeyword', function () {
			// apply a delay so every keypress doesn't start a computation
			Ember.run.debounce(this, this.applyKeywordFilter, 500);
		}),
		onFulltextLoad: Ember.observer('model.fulltext', function () {
			var self = this;
			this.get('model.fulltext').forEach(function (row) {
				self.add_search_item(row);
			});
		}),
		applyKeywordFilter: function applyKeywordFilter() {
			if (this.get('inputKeyword') == '') {
				this.set('searchState.mode', 'browse');
				return;
			}
			this.set("searchState.mode", "keyword");
			this.set('searchState.filter_keyword', this.get('inputKeyword'));
		},
		add_search_item: function add_search_item(row) {
			var item = row.value;
			//console.log(capability_lookup, tag_lookup, item);
			var entry = {
				id: row.id,
				name: item.name,
				description: item.description,
				definition: item.definition,
				duties: item.duties,
				requirements: item.requirements,
				location: item.location.join(" ")

			};
			this.get('index').addDoc(entry);
		},
		search: function search(query) {
			return this.get('index').search(query, { expand: true }).mapBy('ref');
		},


		searchResults: Ember.computed('searchState.mode', 'searchState.filter_keyword', 'sortBy', function () {
			var self = this;
			var filtered = [];
			var keyword_ids = Ember.A(this.search(this.get('searchState.filter_keyword')));
			if (this.get('searchState.mode') == 'keyword') {
				filtered = this.get('sortedCareers').filter(function (career) {
					return keyword_ids.includes(career._id);
				});
			} else {
				filtered = this.get('sortedCareers');
			}
			return filtered;
		}),
		actions: {
			sorting: function sorting(which) {
				this.set('sortBy', which);
			},
			reset: function reset() {
				this.set('inputKeyword', '');
				this.set('searchState.mode', 'browse');
			}
		}
	});
});