define('frontend-app/pods/news/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.set('params', params);

      return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/news/' + params.date + '/' + params.slug), (0, _ajax.default)(_environment.default.base_url + '/api/news?per_page=3&page=1')]).then(function (data) {
        return {
          article: data[0],
          aside: data[1].news,
          url: _environment.default.base_url + '/events/' + params.date + '/' + params.slug
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!this.get('params')) return;
      controller.set('params', this.get('params'));
    },


    afterModel: function afterModel(model) {
      var image = _environment.default.base_url + "/assets/images/otcns-logo.png";
      if (model.article.image_url) {
        image = model.article.image_url;
      } else if (model.article.cover_image) {
        image = _environment.default.base_url + '/download/' + model.article._id + '/' + encodeURIComponent(model.article.cover_image);
      } else if (model.article.header_image) {
        image = _environment.default.base_url + '/download/' + model.article._id + '/' + encodeURIComponent(model.article.header_image);
      }
      this.setHeadTags(model, image);
      this.setTitleToken(model);
    },

    setTitleToken: function setTitleToken(model) {
      this.set('titleToken', model.article.title);
    },

    setHeadTags: function setHeadTags(model, image) {
      var headTags = [{
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.article.title
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: "Advancing Nova Scotia's ocean technology sector."
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'article'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: _environment.default.base_url + '/news/' + this.get('params').date + '/' + this.get('params').slug
        }
      }];

      this.set('headTags', headTags);
    }
  });
});