define('frontend-app/pods/news/index/route', ['exports', 'ember-infinity/mixins/route', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _route, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_route.default, {
		model: function model() {
			return Ember.RSVP.Promise.all([this.infinityModel("news", { perPage: 5, startingPage: 1, modelPath: 'controller.articles' }), (0, _ajax.default)(_environment.default.base_url + '/api/data/media-tags')]).then(function (data) {
				return {
					articles: data[0],
					tags: data[1]
				};
			});
			/*
      return fetch(config.base_url + '/api/news')
       .then(function(response){
       	return response;
   
   }).catch( function(err){
   	console.log(err);
   })
   */
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('articles', model.articles);
			controller.set('tags', model.tags);
		}
	});
});