define('frontend-app/serializers/news', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalize: function normalize(model, hash, prop) {
			hash['doc'] = hash.doc;

			return this._super.apply(this, arguments);
		}
	});
});