define('frontend-app/pods/careers/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    members: Ember.inject.service(),

    model: function model(params) {
      this.set('params', params);
      return (0, _ajax.default)(_environment.default.base_url + '/api/careers/' + params.slug).then(function (career) {
        var URLs = [];
        career.employers.forEach(function (employer) {
          var URL = (0, _ajax.default)(_environment.default.base_url + '/api/data/member-jobs/' + employer);
          URLs.push(URL);
        });
        return Ember.RSVP.Promise.all(URLs).then(function (jobs) {
          var mergedJobs = [].concat.apply([], jobs);
          return {
            career: career,
            jobs: mergedJobs
          };
        });
      });
    },

    afterModel: function afterModel(model) {
      var image = "stand-in";
      if (model.hero_shot) {
        image = _environment.default.base_url + "/download/" + model._id + "/" + model.hero_shot;
      }
      this.setHeadTags(model, image);
      this.setTitleToken(model);
    },

    setTitleToken: function setTitleToken(model) {
      this.set('titleToken', model.name);
    },

    setHeadTags: function setHeadTags(model, image) {
      var headTags = [{
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.name
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: "Advancing Nova Scotia's ocean technology sector."
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: _environment.default.base_url + '/careers/' + this.get('params').slug
        }
      }];

      this.set('headTags', headTags);
    }

  });
});