define('frontend-app/pods/account/index/controller', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),
		account: Ember.inject.service(),

		loaded: false,

		loading_newsletter: false,
		loading_bulletin: false,

		loading_administration: false,

		loading_profile: false,

		is_editing_profile: false,
		error: null,

		actions: {
			toggleDirectory: function toggleDirectory() {
				this.toggleProperty('model.data.include_in_directory');
			},
			edit: function edit() {
				this.set('is_editing_profile', true);
			},
			cancel: function cancel() {
				this.set('is_editing_profile', false);
			},
			save: function save() {
				this.set('loading_profile', true);

				this.set('error', null);

				var self = this;
				var url = _environment.default.base_url + '/api/account/update';

				(0, _ajax.default)(url, {
					method: 'POST',
					body: JSON.stringify({
						name: this.get('model.data.name'),
						surname: this.get('model.data.surname'),
						email: this.get('model.data.email')
					}),
					credentials: 'same-origin'

				}).then(function (response) {

					self.set('model', response);
					self.set('is_editing_profile', false);
					self.set('loading_profile', false);
				}).catch(function (err) {

					// reset the profile
					(0, _ajax.default)(_environment.default.base_url + "/api/account", { credentials: 'same-origin' }).then(function (profile) {
						self.set('model', profile);

						err.json().then(function (error) {
							self.set('is_editing_profile', false);
							self.set('loading_profile', false);
							self.set('error', error);
						});
					});
				});
			}
		}

	});
});