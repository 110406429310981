define("frontend-app/pods/application/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _Controller$extend;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var Controller = Ember.Controller;
	exports.default = Controller.extend((_Controller$extend = {

		session: Ember.inject.service(),
		account: Ember.inject.service(),

		navigation_visible: false,

		currentRoute: Ember.computed("currentRouteName", function () {
			return this.get("currentRouteName").split(".").join("-");
		})

	}, _defineProperty(_Controller$extend, "account", Ember.computed('session.data.authenticated', function () {
		var data = this.get('session.data.authenticated');
		if (!Ember.isEmpty(data)) {
			console.log(data);
			return data;
		}
	})), _defineProperty(_Controller$extend, "actions", {
		toggleNav: function toggleNav() {
			this.toggleProperty("navigation_visible");
		},
		logout: function logout() {
			var self = this;
			self.get('session').invalidate();
		},
		test: function test() {
			fetch('/api/account', { credentials: 'same-origin' }).then(function (response) {
				console.log(response.text());
			});
		}
	}), _Controller$extend));
});