define("frontend-app/transitions", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		// Add your transitions here, like:
		//   this.transition(
		//     this.fromRoute('people.index'),
		//     this.toRoute('people.detail'),
		//     this.use('toLeft'),
		//     this.reverse('toRight')
		//   );

		this.transition(this.hasClass('liquid-tag-list'), this.use("explode", {
			matchBy: "data-id",
			use: ["fly-to", { easing: "easeOutExpo", duration: 200 }]
		}
		//{	use: ["fade", {easing: "easeOutExpo", duration: 200}] }
		));

		this.transition(this.hasClass('liquid-dialog-container'), this.use('crossFade', { easing: "easeOutExpo", duration: 600 }), this.reverse('crossFade', { easing: "easeOutExpo", duration: 600 }));

		/*****************************************
           COMMON STATE TRANSITIONS
   *****************************************/
		this.transition(this.hasClass("slideUp"), this.toValue(true), this.use('toUp', { easing: "easeOutExpo", duration: 600 }), this.reverse('toDown', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("slideDown"), this.toValue(true), this.use('toUp', { easing: "easeOutExpo", duration: 600 }), this.reverse('toDown', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("slideLeft"), this.toValue(true), this.use('toRight', { easing: "easeOutExpo", duration: 600 }), this.reverse('toLeft', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("crossFade"), this.toValue(true), this.use('crossFade', { easing: "easeOutExpo", duration: 600 }), this.reverse('crossFade', { easing: "easeOutExpo", duration: 600 }));

		this.transition(this.hasClass("toggleFade"), this.toValue(true), this.use('fade', { easing: "easeOutExpo", duration: 600 }));
	};
});