define('frontend-app/pods/jobs/view/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment', 'frontend-app/helpers/truncate-text'], function (exports, _ajax, _environment, _truncateText) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		model: function model(params) {

			this.set('params', params);

			return (0, _ajax.default)(_environment.default.base_url + '/api/jobs/' + params.slug + '/' + params._id).then(function (data) {
				return {
					job: data,
					url: _environment.default.base_url + '/jobs/' + params.slug + '/' + params._id
				};
			});
		},

		afterModel: function afterModel(model) {
			this.setTitleToken(model);
			this.setHeadTags(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.job.title);
		},

		setHeadTags: function setHeadTags(model) {
			var self = this;
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.job.title
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: self.sanitizeString((0, _truncateText.truncateText)([model.job.description, 100]))
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'article'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: _environment.default.base_url + "/assets/images/og-image.jpg"
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/jobs/' + model.job.slug
				}
			}];

			this.set('headTags', headTags);
		},

		sanitizeString: function sanitizeString(str) {
			str = str.replace(/<(?:.|\n)*?>/gm, '');
			str = str.replace(/&nbsp;/g, ' ');
			return str.trim();
		}
	});
});