define('frontend-app/helpers/find-vowel', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.findVowel = findVowel;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function findVowel(_ref) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    rest = _ref2.slice(1);

		if (value.charAt(0).match(/[aeiouAEIOU]/)) {
			return true;
		} else {
			return false;
		}
	}

	exports.default = Ember.Helper.helper(findVowel);
});