define('frontend-app/pods/news/view/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		attachmentList: Ember.computed('model.article._attachments', function () {
			var self = this;
			if (!this.get('model.article._attachments')) return [];

			return Object.keys(this.get('model.article._attachments') || []).map(function (item) {
				return {
					filename: item,
					length: self.get('model.article._attachments')[item]['length'],
					image: self.get('model.article._attachments')[item]['content_type'].indexOf('image') != -1 ? true : false
				};
			});
		}),

		images: Ember.computed.filter('attachmentList', function (item) {
			return item.image;
		}),
		files: Ember.computed.filter('attachmentList', function (item) {
			return !item.image;
		})

	});
});