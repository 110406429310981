define('frontend-app/pods/events/archive/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return (0, _ajax.default)(_environment.default.base_url + '/api/events?direction=past').then(function (data) {
				return data.events;
			});
		},

		titleToken: 'Archive'

	});
});