define('frontend-app/pods/careers/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/careers/list'), (0, _ajax.default)(_environment.default.base_url + '/api/careers/fulltext')]).then(function (data) {
				data[0].map(function (item) {

					var prospectScore = 0;
					if (item.prospects == "poor") {
						prospectScore += 1;
					} else if (item.prospects == "fair") {
						prospectScore += 3;
					} else if (item.prospects == "good") {
						prospectScore += 5;
					}
					prospectScore += item.employers.length;
					item.prospectScore = prospectScore;
					return item;
				});
				return {
					careers: data[0],
					fulltext: data[1].rows };
			});
		}
	});
});