define('frontend-app/pods/sign-up/controller', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({

		submission: {
			company: "",
			email: "",
			name: "",
			phone: "",
			address: "",
			province: "",
			company_size: "",
			url: "",
			profile: "",
			products_and_services: "",
			certifications: "",
			non_profit: "no",
			industries: [],
			capabilities: []
		},
		error: false,
		success: false,
		message: "",
		industries: [{ name: "Defence & Security" }, { name: "Energy" }, { name: "Fisheries & Aquaculture" }, { name: "Marine Transportation" }, { name: "Marine Tourism" }],

		actions: {
			setProvince: function setProvince(which) {
				this.set('submission.province', which);
			},
			processApplication: function processApplication() {
				var self = this;
				(0, _ajax.default)(_environment.default.base_url + '/api/process-application', {
					credentials: 'same-origin',
					method: 'post',
					body: JSON.stringify({
						company: this.get('submission.company'),
						email: this.get('submission.email'),
						name: this.get('submission.name'),
						phone: this.get('submission.phone'),
						address: this.get('submission.address'),
						province: this.get('submission.province'),
						company_size: this.get('submission.company_size'),
						url: this.get('submission.url'),
						profile: this.get('submission.profile'),
						products_and_services: this.get('submission.products_and_services'),
						certifications: this.get('submission.certifications'),
						non_profit: this.get('submission.non_profit'),
						industries: this.get('submission.industries').map(function (industry) {
							return { text: industry };
						}),
						capabilities: this.get('submission.capabilities').map(function (capability) {
							return { text: capability };
						})
					})
				}).then(function (response) {
					if (response.error) {
						self.set('error', true);
						self.set('success', false);
						self.set('message', response.error);
					} else if (response.success) {
						self.set('success', true);
						self.set('error', false);
						self.set('message', response.success);
					}
				}).catch(function (err) {
					alert(err);
				});
			}
		}

	});
});