define('frontend-app/pods/events/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/events'), (0, _ajax.default)(_environment.default.base_url + '/api/events?direction=past&per_page=5&page=1')]).then(function (data) {
				return {
					current: data[0].events,
					past: data[1].events
				};
			});
		}
	});
});