define('frontend-app/pods/reset-password/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({

		actions: {
			resetPassword: function resetPassword() {

				var self = this;
				self.controller.set('error', false);
				self.controller.set('success', false);

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/reset_password_confirm?' + 'hash=' + this.get('controller.request') + '&email=' + this.get('controller.email') + '&pass1=' + this.get('controller.pass1') + '&pass2=' + this.get('controller.pass2')).then(function (data) {

					self.controller.set('success', true);
					self.controller.set('message', data);
				}).catch(function (err) {

					console.log(err);
					err.json().then(function (data) {
						self.controller.set('error', true);
						self.controller.set('message', data);
					});
				});
			}
		}

	});
});