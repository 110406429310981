define('frontend-app/pods/jobs/view/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/metadata'], function (exports, _environment, _ajax, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),

		region_lookup: _metadata.default.regions

	});
});