define('frontend-app/pods/jobs/index/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/metadata'], function (exports, _environment, _ajax, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		region_lookup: _metadata.default.regions,

		members: Ember.inject.service(),

		currentMode: 'employer',

		currentFilter: '',

		currentListing: Ember.computed('model', 'currentMode', 'currentFilter', function () {
			var jobs = this.get('model');
			var self = this;
			if (this.get('currentFilter') != '') {
				if (this.get('currentMode') == 'employer') {
					jobs = jobs.filter(function (item) {
						console.log(item);
						if (item.doc.tagged_companies) {
							return item.doc.tagged_companies.indexOf(self.get('currentFilter')) != -1;
						} else {
							return false;
						}
					});
				}
				if (this.get('currentMode') == 'career') {
					jobs = jobs.filter(function (item) {
						if (item.doc.tagged_career_pathways) {
							return item.doc.tagged_career_pathways.indexOf(self.get('currentFilter')) != -1;
						} else {
							return false;
						}
					});
				}
				if (this.get('currentMode') == 'region') {
					jobs = jobs.filter(function (item) {
						if (item.doc.region) {
							return item.doc.region.indexOf(self.get('currentFilter')) != -1;
						} else {
							return false;
						}
					});
				}
			}
			return jobs;
		}),

		all_employers: Ember.computed('model', function () {

			var employers = Ember.A();
			var self = this;
			this.get('model').forEach(function (item) {
				employers = employers.concat(item.doc.tagged_companies);
			});
			return employers.uniq();
		}),

		all_careers: Ember.computed('model', function () {

			var careers = Ember.A();
			var self = this;
			this.get('model').forEach(function (item) {
				careers = careers.concat(item.doc.tagged_career_pathways);
			});
			return careers.uniq();
		}),

		actions: {
			changeMode: function changeMode(which) {
				this.set('currentMode', which);
				this.set('currentFilter', '');
			},
			toggleFilter: function toggleFilter(which) {
				if (which == this.get('currentFilter')) {
					this.set('currentFilter', '');
				} else this.set('currentFilter', which);
			}
		}

	});
});