define('frontend-app/pods/events/view/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.set('params', params);
      return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/events/' + params.date + '/' + params.slug), (0, _ajax.default)(_environment.default.base_url + '/api/events')]).then(function (data) {
        console.log(data[0]);
        return {
          event: data[0],
          aside: data[1].events,
          url: _environment.default.base_url + '/events/' + params.date + '/' + params.slug
        };
      });
    },


    afterModel: function afterModel(model) {
      var image = _environment.default.base_url + "/assets/images/otcns-logo.png";
      this.setHeadTags(model, image);
      this.setTitleToken(model);
    },

    setTitleToken: function setTitleToken(model) {
      this.set('titleToken', model.event.title);
    },

    setHeadTags: function setHeadTags(model, image) {
      var headTags = [{
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.event.title
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: "Advancing Nova Scotia's ocean technology sector."
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: _environment.default.base_url + '/events/' + this.get('params').date + '/' + this.get('params').slug
        }
      }];

      this.set('headTags', headTags);
    }

  });
});