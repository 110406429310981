define("frontend-app/helpers/format-income", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.formatIncome = formatIncome;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function formatIncome(_ref) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    rest = _ref2.slice(1);

		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	exports.default = Ember.Helper.helper(formatIncome);
});