define('frontend-app/pods/members/index/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		members: Ember.inject.service(),

		model: function model() {
			return Ember.RSVP.Promise.all([this.get('members').getData('members'), this.get('members').getData('members_geolocated')]).then(function (data) {
				return {
					members: data[0],
					members_geolocated: data[1]
				};
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			this.set('members.currentPage', 'members');
		}
	});
});