define('frontend-app/helpers/truncate-text', ['exports', 'npm:ellipsize'], function (exports, _npmEllipsize) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.truncateText = truncateText;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function truncateText(_ref) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    chars = _ref2[1],
		    rest = _ref2.slice(2);

		return (0, _npmEllipsize.default)(value, chars);
	}

	exports.default = Ember.Helper.helper(truncateText);
});