define('frontend-app/pods/components/member-tile-listing/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({

		members: Ember.inject.service(),

		grid: null,
		onFilter: Ember.observer('filtered', function () {
			this.filterGrid();
		}),
		capability_index_lookup: Ember.computed('members.all_capabilities', function () {
			var lookup = {};
			this.get('members.all_capabilities').forEach(function (item, i) {
				lookup[item._id] = i % 10 + 1;
			});
			return lookup;
		}),
		didInsertElement: function didInsertElement() {

			this.set('grid', $(".tiles"));

			this.get('grid').isotope({
				getSortData: {
					name: function name(item) {
						return $(item).find("DIV.tile-window").attr("data-name");
					}
				},
				sortBy: 'name',
				itemSelector: 'DIV.tile',
				columnWidth: 'DIV.tile'
			});
			this.filterGrid();
		},
		filterGrid: function filterGrid() {
			if (this.get('filtered').length != 0) {
				var classNames = this.get('filtered').mapBy('id').join(', .');
				this.get('grid').isotope({ filter: ".nonmember, ." + classNames });
			} else {
				this.get('grid').isotope({ filter: ".nonmember, .member.nothing" });
			}
		}
	});
});