define("frontend-app/metadata", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		regions: {
			"NS": "Nova Scotia",
			"NB": "New Brunswick",
			"NL": "Newfoundland & Labrador",
			"PE": "Prince Edward Island",
			"QC": "Quebec",
			"ON": "Ontario",
			"MB": "Manitoba",
			"SK": "Saskatchewan",
			"AB": "Alberta",
			"BC": "British Columbia",
			"YT": "Yukon",
			"NT": "Northwest Territories",
			"NU": "Nunavut"
		},

		fileIcons: {
			"text/plain": "fa-file-text-o",
			"text/csv": "fa-file-text-o",
			"image/jpeg": "fa-file-image-o",
			"image/png": "fa-file-image-o",
			"application/pdf": "fa-file-pdf-o",
			"application/zip": "fa-file-zip-o",
			"application/vnd.ms-powerpoint": "fa-file-powerpoint-o",
			"application/vnd.ms-excel": "fa-file-excel-o",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa-file-word-o",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": "fa-file-powerpoint-o",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa-file-excel-o"
		}
	};
});