define('frontend-app/pods/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/events?per_page=3&page=1'), (0, _ajax.default)(_environment.default.base_url + '/api/events?direction=past&per_page=3&page=1'), (0, _ajax.default)(_environment.default.base_url + '/api/news?per_page=5&page=1'), (0, _ajax.default)(_environment.default.base_url + '/api/banners')]).then(function (data) {
				return {
					events: data[0].events,
					past_events: data[1].events,
					news: data[2].news,
					banners: data[3].rows
				};
			}).catch(function (err) {
				console.log(err);
			});
		}
	});
});