define('frontend-app/pods/news/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),
		currentMode: 'all',
		currentTag: '',
		moreTags: false,
		limitedTags: Ember.computed('uniqueTags', function () {
			return this.get('uniqueTags').slice(0, 11);
		}),

		currentListing: Ember.computed('articles.[]', 'currentTag', function () {
			var articles = this.get('articles');
			var self = this;
			if (this.get('currentTag')) {
				articles = articles.filter(function (item) {
					return item.get('doc.otcns_tags').indexOf(self.get('currentTag')) != -1;
				});
			}
			return articles;
		}),

		uniqueTags: Ember.computed('tags.[]', function () {
			var tags = Ember.A();
			this.get('tags').forEach(function (item) {
				tags.push(item.name);
			});
			var unique_names = tags.uniq();
			return unique_names;
		}),

		actions: {
			changeMode: function changeMode(which) {
				this.set('currentMode', which);
				this.send('infinityLoad', this.get('model.articles'));
			},
			toggleTag: function toggleTag(which) {
				if (which == this.get('currentTag')) this.set('currentTag', '');else if (which == "All News") this.set('currentTag', '');else this.set('currentTag', which);
				this.send('infinityLoad', this.get('model.articles'));
			},
			clearTag: function clearTag() {
				this.set('currentTag', "");
				this.send('infinityLoad', this.get('model.articles'));
			},
			toggleMoreTags: function toggleMoreTags() {
				this.toggleProperty('moreTags');
			}
		}
	});
});