define('frontend-app/pods/capabilities/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({

		members: Ember.inject.service(),

		model: function model() {
			return this.get('members').getData('members_geolocated');
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			this.set('members.currentPage', 'capabilities');
		},

		titleToken: 'Capabilities'

	});
});