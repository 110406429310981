define('frontend-app/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'frontend-app/config/environment'], function (exports, _applicationRouteMixin, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

		title: function title(tokens) {
			tokens = Ember.makeArray(tokens);
			tokens.unshift('OTCNS');
			return tokens.reverse().join(' | ');
		},

		actions: {
			willTransition: function willTransition() {
				this.set('controller.navigation_visible', false);
			}
		}

	});
});