define('frontend-app/pods/jobs/index/route', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		model: function model() {
			return (0, _ajax.default)(_environment.default.base_url + '/api/jobs').then(function (data) {
				return data;
			});
		}
	});
});