define('frontend-app/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'npm:pouchdb', 'npm:pouchdb-authentication', 'frontend-app/config/environment', 'ember-fetch/ajax'], function (exports, _base, _npmPouchdb, _npmPouchdbAuthentication, _environment, _ajax) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		fastboot: Ember.inject.service(),
		isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

		restore: function restore(data) {

			console.log("Restoring a session.");
			console.log(data);
			var self = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {

				if (self.get('isFastBoot')) resolve(data);

				(0, _ajax.default)(_environment.default.base_url + '/db/_session', { credentials: 'same-origin' }).then(function (response) {
					if (response.userCtx.name === null) reject();

					(0, _ajax.default)(_environment.default.base_url + '/api/account', { credentials: 'same-origin' }).then(function (userdoc) {
						resolve(userdoc);
					});
				}).catch(function (err) {
					//console.log('error', err)
					reject(err);
				});
			});
		},

		authenticate: function authenticate(options) {
			var self = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {

				_npmPouchdb.default.plugin(_npmPouchdbAuthentication.default);
				var db = new _npmPouchdb.default(_environment.default.remote_couch + 'test', { skipSetup: true });
				db.login(options.user, options.pass).then(function (response) {

					if (response.name === null) {

						// _admin role records don't show the name in the first _session call
						// https://issues.apache.org/jira/browse/COUCHDB-1356

						return db.getSession().then(function (subsequent) {

							if ("name" in subsequent.userCtx) {
								return db.getUser(subsequent.userCtx.name);
							} else {
								reject();
							}
						});
					}

					// normal user logged in
					return db.getUser(response.name);
				}, function (xhr) {

					reject(xhr);
				}).then(function (response) {
					if (response.roles.indexOf("otcns") == -1) reject({ reason: "You haven't been given permission to access this site." });

					Ember.run(function () {
						(0, _ajax.default)(_environment.default.base_url + '/api/account', { credentials: 'same-origin' }).then(function (userdoc) {
							resolve(userdoc);
						});
					});
				}).catch(function (err) {});
			});
		},

		invalidate: function invalidate() {

			console.log('Invalidating session');
			var self = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {

				_npmPouchdb.default.plugin(_npmPouchdbAuthentication.default);
				var db = new _npmPouchdb.default(_environment.default.remote_couch + '/test', { skipSetup: true });
				db.logout(function (err, response) {
					if (err) {
						Ember.run(function () {
							reject(err);
						});
					} else {
						resolve(response);
					}
				});
			});
		}

	});
});