define('frontend-app/router', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;
  var get = Ember.get;
  var service = Ember.inject.service;
  var scheduleOnce = Ember.run.scheduleOnce;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('about');
    this.route('account', function () {
      this.route('edit');
    });
    this.route('members', function () {
      this.route('view', { path: '/:slug' });
    });
    this.route('careers', function () {
      this.route('view', { path: '/:slug' });
      this.route('privacy-policy');
    });
    this.route('partners');
    this.route('news', function () {
      this.route('view', { path: '/:date/:slug' });
    });
    this.route('events', function () {
      this.route('view', { path: '/:date/:slug' });
      this.route('archive');
    });
    this.route('contact');
    this.route('sign-up');
    this.route('sign-in');
    this.route('reset-password');
    this.route('forgot-password');
    this.route('activate-account');
    this.route('capabilities');
    this.route('jobs', function () {
      this.route('view', { path: '/:slug/:_id' });
    });
  });
  exports.default = Router;
});