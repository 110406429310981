define('frontend-app/pods/careers/view/controller', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({

		members: Ember.inject.service(),

		showJobDescription: false,

		jobSortDefinition: ['doc.deadline'],

		jobsSorted: Ember.computed.sort('model.jobs', 'jobSortDefinition'),

		url: Ember.computed('model.career', function () {
			return _environment.default.base_url + '/careers/' + this.get('model.career.slug');
		}),

		is_postsecondary: Ember.computed('model.career', function () {
			return this.get('model.career.pathway_certificate_diploma') != "" || this.get('model.career.pathway_undergraduate') != "";
		}),

		is_both_postsecondaries: Ember.computed('model.career', function () {
			return this.get('model.career.pathway_certificate_diploma') != "" && this.get('model.career.pathway_undergraduate') != "";
		}),

		postsecondary_heading: Ember.computed("is_both_postsecondaries", function () {
			var certificates = this.get('model.career.pathway_certificate_diploma');
			var undergraduate = this.get('model.career.pathway_undergraduate');

			if (certificates != "") return "Certificates, Diplomas, Community College";
			if (undergraduate != "") return "University Bachelor's Degree";
		}),

		postsecondary_data_single: Ember.computed("model", function () {
			var certificates = this.get('model.career.pathway_certificate_diploma');
			var undergraduate = this.get('model.career.pathway_undergraduate');

			if (certificates != "") {
				if (certificates.length > 1) {
					return certificates;
				} else {
					return certificates[0];
				}
			} else {
				if (undergraduate.length > 1) {
					return undergraduate;
				} else {
					return undergraduate[0];
				}
			}
		}),

		actions: {

			toggleJobDescription: function toggleJobDescription() {
				this.toggleProperty('showJobDescription');
			}
		}

	});
});