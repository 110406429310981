define('frontend-app/pods/account/index/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),

		model: function model() {
			return (0, _ajax.default)(_environment.default.base_url + "/api/account", { credentials: 'same-origin' });
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (model.data) {
				controller.set('loaded', true);
			}
		}
	});
});