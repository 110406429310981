define('frontend-app/pods/members/view/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		model: function model(params) {

			this.set('params', params);

			return (0, _ajax.default)(_environment.default.base_url + '/api/data/member/' + params.slug).then(function (company) {

				return Ember.RSVP.Promise.all([(0, _ajax.default)(_environment.default.base_url + '/api/data/member-news/' + company._id), (0, _ajax.default)(_environment.default.base_url + '/api/data/member-jobs/' + company._id), (0, _ajax.default)(_environment.default.base_url + '/api/data/member-files/' + company._id)]).then(function (data) {
					return {
						member: company,
						articles: data[0].rows.reverse(),
						jobs: data[1],
						files: data[2]
					};
				});
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('articles', model.articles);
			controller.set('member', model.member);
		},


		afterModel: function afterModel(model) {
			var image = "stand-in";
			if (model.member.logo) {
				image = _environment.default.base_url + "/download/" + model.member._id + '/' + encodeURIComponent(model.member.logo);
			}
			this.setHeadTags(model, image);
			this.setTitleToken(model);
		},

		setTitleToken: function setTitleToken(model) {
			this.set('titleToken', model.name);
		},

		setHeadTags: function setHeadTags(model, image) {
			var headTags = [{
				type: 'meta',
				attrs: {
					property: 'og:title',
					content: model.name
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:type',
					content: 'website'
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:description',
					content: "Check out their company profile, along with dozens of other Nova Scotian businesses who are advancing Nova Scotia's ocean technology sector."
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:image',
					content: image
				}
			}, {
				type: 'meta',
				attrs: {
					property: 'og:url',
					content: _environment.default.base_url + '/members/' + this.get('params').slug
				}
			}];
			this.set('headTags', headTags);
		}

	});
});