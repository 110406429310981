define('frontend-app/pods/components/popup-layer/component', ['exports', 'ember-leaflet/components/popup-layer'], function (exports, _popupLayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run,
      get = Ember.get;
  exports.default = _popupLayer.default.extend({
    _addPopupListeners: function _addPopupListeners() {
      var _this = this;

      // we need to hijack the `onAdd` method because we need to
      // render the template *before* the popup is opened.
      // This way, the popup will set its dimensions according to the rendered DOM.
      var oldOnAdd = this._layer.onAdd;
      this._layer.onAdd = function (map) {
        // if we're currently waiting for the animation to end, cancel the wait
        run.cancel(_this._destroyAfterAnimation);
        // this will make wormwhole render to the document fragment
        _this.set('shouldRender', true);
        // ember-wormhole will render on the afterRender queue, so we need to render after that
        run.next(function () {
          oldOnAdd.call(_this._layer, map);
        });
      };
      // we need to user `layerremove` event becase it's the only one that fires
      // *after* the popup was completely removed from the map

      var parentContainer = get(this, 'parentComponent.parentComponent');
      var map = get(parentContainer, '_layer._map');
      if (map) {
        map.addEventListener('layerremove', this._onLayerRemove, this);
      }
    },


    _removePopupListeners: function _removePopupListeners() {
      var parentComponent = this.get('parentComponent.parentComponent');
      var map = get(parentComponent, '_layer._map');
      if (map) {
        map.removeEventListener('layerremove', this._onLayerRemove, this);
      }
    }
  });
});