define('frontend-app/helpers/strip-tags', ['exports', 'ember-cli-sanitize-html/utils/sanitize-html'], function (exports, _sanitizeHtml) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.stripTags = stripTags;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function stripTags(_ref) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    rest = _ref2.slice(1);

		var val = (0, _sanitizeHtml.default)(value || '', {
			transformTags: {
				'br': function br(tagName, attribs) {
					return {
						tagName: 'p',
						text: ' '
					};
				}
			}
		});
		return (0, _sanitizeHtml.default)(val || '', {
			allowedTags: [],
			allowedAttributes: []
		});
	}

	exports.default = Ember.Helper.helper(stripTags);
});