define('frontend-app/pods/activate-account/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({

		actions: {
			activateAccount: function activateAccount() {

				var self = this;
				self.controller.set('error', false);
				self.controller.set('success', false);

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/activate_account', {

					method: 'post',
					body: JSON.stringify({
						email: this.get('controller.email'),
						hash: this.get('controller.request'),
						pass1: this.get('controller.pass1'),
						pass2: this.get('controller.pass2')
					})

				}).then(function (data) {

					self.controller.set('success', true);
					self.controller.set('message', data);
				}).catch(function (err) {

					console.log(err);
					err.json().then(function (data) {
						self.controller.set('error', true);
						self.controller.set('message', data);
					});
				});
			}
		}

	});
});