define('frontend-app/pods/components/career-tile-listing/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		grid: null,
		onFilter: Ember.observer('filtered', function () {
			if (this.get('filtered').length != 0) {
				var classNames = this.get('filtered').mapBy('_id').join(',.career.');
				this.get('grid').isotope({ filter: ".career." + classNames });
			} else {
				this.get('grid').isotope({ filter: ".career.nothing" });
			}
		}),
		onSortChange: Ember.observer('sortBy', function () {
			this.get('grid').isotope({ sortBy: this.get('sortBy') });
		}),

		didInsertElement: function didInsertElement() {

			this.set('grid', $(".tiles"));

			this.get('grid').isotope({
				getSortData: {
					name: function name(item) {
						return $(item).find("DIV.tile-window").attr("data-name");
					},
					income: function income(item) {
						return parseInt($(item).find("DIV.tile-window").attr("data-income"), 10);
					},
					prospects: function prospects(item) {
						return parseInt($(item).find("DIV.tile-window").attr("data-prospectScore"), 10);
					}
				},
				itemSelector: 'DIV.tile',
				columnWidth: 'DIV.tile',
				sortAscending: {
					name: true,
					income: false,
					prospects: false
				}
			});
		},


		actions: {}
	});
});