define('frontend-app/services/members', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'npm:elasticlunr', 'frontend-app/metadata'], function (exports, _environment, _ajax, _npmElasticlunr, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		currentPage: 'members',

		all_members: Ember.A(),
		all_capabilities: Ember.A(),
		all_tags: Ember.A(),
		all_career_pathways: Ember.A(),
		all_locations: Ember.computed(function () {
			var keys = Object.keys(_metadata.default.regions);
			return keys.map(function (key) {
				return Ember.Object.create({ key: key, label: _metadata.default.regions[key] });
			});
		}),

		all_members_search: Ember.computed("all_members", function () {
			var temp = [];
			this.get('all_members').forEach(function (current_member) {
				if (current_member.value.display_in_otcns_database == true) temp.push(current_member);
			});
			return temp;
		}),

		members_geolocated: [],

		capability_lookup: Ember.computed("all_capabilities", function () {
			return this.get('all_capabilities').reduce(function (prev, current, list) {
				prev[current._id] = current.name;
				return prev;
			}, {});
		}),

		tag_lookup: Ember.computed("all_tags", function () {
			return this.get('all_tags').reduce(function (prev, current, list) {
				prev[current._id] = current.name;
				return prev;
			}, {});
		}),

		member_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.name;
				return prev;
			}, {});
		}),

		member_slug_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.slug;
				return prev;
			}, {});
		}),

		member_logo_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.logo;
				return prev;
			}, {});
		}),

		member_is_visible_lookup: Ember.computed("all_members", function () {
			return this.get('all_members').reduce(function (prev, current, list) {
				prev[current.id] = current.value.display_in_otcns_database;
				return prev;
			}, {});
		}),

		career_pathway_lookup: Ember.computed("all_career_pathways", function () {
			return this.get('all_career_pathways').reduce(function (prev, current, list) {
				prev[current.id] = current.value.name;
				return prev;
			}, {});
		}),

		career_pathway_slug_lookup: Ember.computed("all_career_pathways", function () {
			return this.get('all_career_pathways').reduce(function (prev, current, list) {
				prev[current.id] = current.value.slug;
				return prev;
			}, {});
		}),

		unique_capabilities: Ember.computed('searchResults.[]', 'all_capabilities.[]', "searchState.mode", 'currentPage', function () {

			var caps = Ember.A();
			var self = this;
			this.get('searchResults').forEach(function (item) {
				caps = caps.concat(item.value.capabilities);
			});
			var unique_ids = caps.uniq();
			return this.get('all_capabilities').filter(function (item) {
				if (unique_ids.includes(item._id)) return true;
				return false;
			});
		}),

		unique_tags: Ember.computed('searchResults.[]', "searchState.mode", "currentPage", function () {

			var tags = Ember.A();

			// don't show tags unless a capability is selected
			if (this.get('searchState.filter_capability.length') == 0) return [];

			this.get('searchResults').forEach(function (item) {
				tags = tags.concat(item.value.tags);
			});
			var unique_ids = tags.uniq();
			return this.get('all_tags').filter(function (item) {
				if (unique_ids.includes(item.id)) return true;
				return false;
			});
		}),

		unique_locations: Ember.computed('searchResults.[]', "searchState.mode", "currentPage", function () {

			var locs = Ember.A();
			this.get('searchResults').forEach(function (item) {
				locs = locs.concat(item.value.locations);
			});
			var unique_keys = locs.uniq();
			return this.get('all_locations').filter(function (item) {
				if (unique_keys.includes(item.key)) return true;
				return false;
			});
		}),

		index: (0, _npmElasticlunr.default)(function () {
			this.addField('name');
			this.addField('description');
			this.addField('capabilities');
			this.addField('tags');
			this.setRef('id');
		}),

		searchState: Ember.Object.create({
			mode: 'browse',
			filter_sector: '',
			filter_region: Ember.A(),
			filter_capability: Ember.A(),
			filter_tag: Ember.A(),
			filter_keyword: ''
		}),
		inputKeyword: '',

		searchResults: Ember.computed('all_members_search.[]', 'searchState.mode', 'searchState.filter_sector', 'searchState.filter_region.[]', 'searchState.filter_capability.[]', 'searchState.filter_tag.[]', 'searchState.filter_keyword', function () {
			var self = this;
			var filtered = [];

			if (this.get('searchState.mode') == 'browse') {

				if (this.get('searchState.filter_sector') == "" && this.get('searchState.filter_capability.length') == 0 && this.get('searchState.filter_region.length') == 0 && this.get('searchState.filter_tag.length') == 0) return this.get('all_members_search');

				filtered = this.get('all_members_search');

				if (this.get('searchState.filter_sector') != "") {
					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						if (current_member.value.sectors.indexOf(self.get('searchState.filter_sector')) != -1) temp.push(current_member);
					});
					filtered = temp;
				}

				// Before each step below, scan filtered and compile list of unique items
				// then, remove any filter IDs that are not present in that list from searchState.
				// This will prevent an existing filter from cancelling out any search results
				if (this.get('searchState.filter_capability.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						// ensure member is only included once if more than one capability matches
						var include_member = false;
						self.get('searchState.filter_capability').forEach(function (current_capability) {
							if (current_member.value.capabilities.indexOf(current_capability._id) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}

				if (this.get('searchState.filter_tag.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						self.get('searchState.filter_tag').forEach(function (current_tag) {
							if (current_member.value.tags.indexOf(current_tag.id) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}

				if (this.get('searchState.filter_region.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						self.get('searchState.filter_region').forEach(function (current_region) {
							if (current_member.value.locations.indexOf(current_region.key) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}
			} else if (this.get('searchState.mode') == 'keyword') {

				console.log(this.get('searchState.filter_keyword'));

				var keyword_ids = Ember.A(this.search(this.get('searchState.filter_keyword')));
				filtered = this.get('all_members_search').filter(function (member) {
					return keyword_ids.includes(member.id);
				});
			}

			return filtered;
		}),

		searchResultsGeolocated: Ember.computed('members_geolocated.[]', 'searchState.mode', 'searchState.filter_sector', 'searchState.filter_region.[]', 'searchState.filter_capability.[]', 'searchState.filter_tag.[]', 'searchState.filter_keyword', function () {
			var self = this;
			var filtered = [];

			if (this.get('searchState.mode') == 'browse') {

				if (this.get('searchState.filter_sector') == "" && this.get('searchState.filter_capability.length') == 0 && this.get('searchState.filter_region.length') == 0 && this.get('searchState.filter_tag.length') == 0) return this.get('members_geolocated');

				filtered = this.get('members_geolocated');

				if (this.get('searchState.filter_sector') != "") {
					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						if (current_member.value.sectors.indexOf(self.get('searchState.filter_sector')) != -1) temp.push(current_member);
					});
					filtered = temp;
				}

				// Before each step below, scan filtered and compile list of unique items
				// then, remove any filter IDs that are not present in that list from searchState.
				// This will prevent an existing filter from cancelling out any search results
				if (this.get('searchState.filter_capability.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						// ensure member is only included once if more than one capability matches
						var include_member = false;
						self.get('searchState.filter_capability').forEach(function (current_capability) {
							if (current_member.value.capabilities.indexOf(current_capability._id) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}

				if (this.get('searchState.filter_tag.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						self.get('searchState.filter_tag').forEach(function (current_tag) {
							if (current_member.value.tags.indexOf(current_tag.id) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}

				if (this.get('searchState.filter_region.length')) {

					var temp = [];
					filtered.forEach(function (current_member) {
						var include_member = false;
						self.get('searchState.filter_region').forEach(function (current_region) {
							if (current_member.value.locations.indexOf(current_region.key) != -1) include_member = true;
						});
						if (include_member) temp.push(current_member);
					});
					filtered = temp;
				}
			} else if (this.get('searchState.mode') == 'keyword') {

				var keyword_ids = Ember.A(this.search(this.get('searchState.filter_keyword')));
				filtered = this.get('members_geolocated').filter(function (member) {
					return keyword_ids.includes(member.id);
				});
			}

			return filtered;
		}),

		sortResultsBy: ['value.name'],
		sortedSearchResults: Ember.computed.sort('searchResults', 'sortResultsBy'),

		onFilterTextChange: Ember.observer('inputKeyword', function () {
			// apply a delay so every keypress doesn't start a computation
			Ember.run.debounce(this, this.applyKeywordFilter, 500);
		}),

		applyKeywordFilter: function applyKeywordFilter() {
			if (this.get('inputKeyword') == '') {
				this.set('searchState.mode', 'browse');
				return;
			}
			this.set("searchState.mode", "keyword");
			this.set('searchState.filter_keyword', this.get('inputKeyword'));
		},

		init: function init() {
			this._super.apply(this, arguments);
			var self = this;

			Ember.RSVP.Promise.all([this.getData('capabilities'), this.getData('tags'), this.getData('career_pathways'), this.getData('members')]).then(function (data) {

				// build the full text index
				data[3].forEach(function (row) {
					//console.log(row);
					self.add_search_item(row);
				});
			}).catch(function (err) {
				console.log(JSON.stringify(err));
			});
		},
		add_search_item: function add_search_item(row) {
			var capability_lookup = this.get('capability_lookup');
			var tag_lookup = this.get('tag_lookup');
			var item = row.value;
			//console.log(capability_lookup, tag_lookup, item);
			var entry = {
				id: row.id,
				name: item.name,
				description: item.description,
				capabilities: item.capabilities.map(function (capability) {
					return capability_lookup[capability];
				}).join(" "),
				tags: item.tags.map(function (tag) {
					return tag_lookup[tag];
				}).join(" ")

			};
			this.get('index').addDoc(entry);
		},
		getData: function getData(which) {
			// can be members, capabilities or tags
			var self = this;

			if (which == 'members') {
				if (this.get('all_members.length') === 0) {

					return (0, _ajax.default)(_environment.default.base_url + '/api/data/member-fulltext').then(function (data) {
						self.set('all_members', data.rows);
						return data.rows;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_members'));
					});
				}
			}

			if (which == 'capabilities') {
				if (this.get('all_capabilities').length === 0) {
					return (0, _ajax.default)(_environment.default.base_url + '/api/data/capabilities').then(function (data) {
						self.set('all_capabilities', data);
						return data;
					});
				} else {
					return this.get('all_capabilities');
				}
			}

			if (which == 'tags') {
				if (this.get('all_tags').length === 0) {
					return (0, _ajax.default)(_environment.default.base_url + '/api/data/tags').then(function (data) {
						self.set('all_tags', data);
						return data;
					});
				} else {
					return this.get('all_tags');
				}
			}

			if (which == 'career_pathways') {
				if (this.get('all_career_pathways.length') === 0) {

					return (0, _ajax.default)(_environment.default.base_url + '/api/careers/fulltext').then(function (data) {
						self.set('all_career_pathways', data.rows);
						return data.rows;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_career_pathways'));
					});
				}
			}

			if (which == 'members_geolocated') {
				if (this.get('members_geolocated').length === 0) {
					return (0, _ajax.default)(_environment.default.base_url + '/api/data/locations').then(function (data) {
						self.set('members_geolocated', data);
					});
				} else {
					return this.get('members_geolocated');
				}
			}
		},
		search: function search(query) {
			return this.get('index').search(query, { expand: true }).mapBy('ref');
		},
		load: function load() {
			var self = this;
			return new Ember.RSVP.Promise(function (resolve, reject) {
				return self.getData('members').then(function (data) {
					resolve(self.get('all_members'));
				});
			});
		}
	});
});