define('frontend-app/pods/members/index/controller', ['exports', 'frontend-app/metadata', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _metadata, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		members: Ember.inject.service(),

		fastboot: Ember.inject.service(),
		isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

		bounds: Ember.computed('members.searchResultsGeolocated.[]', function () {
			if (this.get('isFastBoot')) return;
			var corner1 = window.L.latLng(49.175268, -52.706079),
			    corner2 = window.L.latLng(44.377553, -123.383806),
			    defaultBounds = window.L.latLngBounds(corner1, corner2);
			if (this.get('members.searchResultsGeolocated').length == 0) {
				return defaultBounds;
			}
			var b = window.L.latLngBounds(this.get('members.searchResultsGeolocated').map(function (item) {
				return item.value.geometry;
			}));
			return b;
		}),

		region_lookup: _metadata.default.regions,

		filtered_members: Ember.computed("model", function () {
			return this.get('model');
		}),

		capability_index_lookup: Ember.computed('members.all_capabilities', function () {
			var lookup = {};
			this.get('members.all_capabilities').forEach(function (item, i) {
				lookup[item._id] = i % 10 + 1;
			});
			return lookup;
		}),

		isShowingModal: false,
		loading: false,
		successText: '',
		errorText: '',
		csv: Ember.Object.create({
			name: '',
			surname: '',
			email: ''
		}),

		actions: {
			reset: function reset() {
				this.set('members.inputKeyword', '');
				this.set('members.searchState', Ember.Object.create({
					mode: 'browse',
					filter_sector: '',
					filter_region: Ember.A(),
					filter_capability: Ember.A(),
					filter_tag: Ember.A(),
					filter_keyword: ''
				}));
			},
			selectSector: function selectSector(which) {
				this.set('members.searchState.filter_sector', which);
			},
			toggleCapability: function toggleCapability(which) {
				if (this.get('members.searchState.filter_capability').includes(which)) {
					this.set('members.searchState.filter_capability', this.get('members.searchState.filter_capability').without(which));
				} else {
					this.get('members.searchState.filter_capability').pushObject(which);
				}
			},
			toggleMobileCapability: function toggleMobileCapability(which) {
				this.set('members.searchState.filter_capability', []);
				if (which != "all") {
					var result = this.get('members.all_capabilities').filter(function (capability) {
						return capability._id === which;
					});
					this.set('members.searchState.filter_capability', []);
					this.get('members.searchState.filter_capability').pushObject(result[0]);
					console.log(this.get('members.searchState.filter_capability'));
				}
			},
			toggleTag: function toggleTag(which) {
				if (this.get('members.searchState.filter_tag').includes(which)) {
					this.set('members.searchState.filter_tag', this.get('members.searchState.filter_tag').without(which));
				} else {
					this.get('members.searchState.filter_tag').pushObject(which);
				}
			},
			toggleRegion: function toggleRegion(which) {
				console.log(which);
				if (this.get('members.searchState.filter_region').includes(which)) {
					this.set('members.searchState.filter_region', this.get('members.searchState.filter_region').without(which));
				} else {
					this.get('members.searchState.filter_region').pushObject(which);
				}
				console.log(this.get('members.searchState.filter_region'));
			},
			testSearch: function testSearch() {
				console.log(this.get('members').search('energy'));
			},


			toggleModal: function toggleModal() {
				this.toggleProperty('isShowingModal');
			},

			submitCSV: function submitCSV() {
				var self = this;
				this.toggleProperty('loading');
				(0, _ajax.default)(_environment.default.base_url + '/api/send-csv', {
					credentials: 'same-origin',
					method: 'post',
					body: JSON.stringify({
						name: this.get('csv.name'),
						surname: this.get('csv.surname'),
						email: this.get('csv.email'),
						searchState: this.get('members.searchState'),
						results: this.get('members.searchResults').mapBy('id')
					})

				}).then(function (response) {
					console.log(response);
					self.toggleProperty('loading');
					self.set('errorText', '');
					self.set('successText', response.success);
				}).catch(function (err) {
					err.json().then(function (error_data) {
						console.log(error_data);
						self.toggleProperty('loading');
						self.set('successText', '');
						self.set('errorText', error_data.error);
					});
				});
			}
		}
	});
});